.auth_placement {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(#B4EC51, #429321);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.auth_holder {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.amplify-form {
  height: 200px;
}

.auth_holder ::placeholder {
  color: white;
  opacity: 0.8; /* Firefox */
}
