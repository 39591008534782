* {
  font-family: Montserrat;
  font-weight: 800;
}

@font-face {
  font-family: Montserrat;
  src: url('./assets/static/Montserrat-Regular.ttf') format("opentype");
}

@font-face {
  font-family: MontserratLight;
  src: url('./assets/static/Montserrat-Light.ttf') format("opentype");
}

@font-face {
  font-family: MontserratThin;
  src: url('./assets/static/Montserrat-Thin.ttf') format("opentype");
}
