:root {
  --btn-font-size: 12px;
}

.admin_dashboard {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.admin_action_bar {
  width: 100%;
  height: 10%;
  display: flex;
  position: relative;
  justify-content: space-around;
  align-items: center;
}

.admin_content {
  width: 100%;
  height: 90%;
  position: relative;
}

.business_info_w_users {
  width: 100%;
  height: 50%;
  position: relative;
  display: flex;
  flex-direction: row;
}

.business_info_container {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.business_content {
  position: relative;
  background-color: white;
  width: 80%;
  height: 80%;
  left: 5%;
  overflow-y: auto;
}

.business_header {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-between;
  border-bottom: 1px solid lightgrey;
  left: 5%;
  font-size: 10px;
}

.business_body_left {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 90%;
  justify-content: space-between;
  left: 5%;
  font-size: 10px;
}

.business_created_date {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 8px;
  color: grey;
}

.user_count {
  position: relative;
  font-size: 12px;
  color: white;
  margin: 0;
  width: 90%;
  text-align: left;
}

.business_int {
  text-align: center;
}

.user_info_container {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.user_content {
  display: flex;
  flex-direction: column;
  width: 95%;
  height: 80%;
  left: 5%;
}

.user_list_container {
  height: 70%;
  overflow-y: auto;
}

.user_headers_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: center;
  position: relative;
  color: white;
  margin: 0;
  height: 40px;
  width: 90%;
}

.user_headers_container p {
  margin: 0;
}

.locations_container {
  width: 100%;
  height: 50%;
  position: relative;
}

.title_container {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  left: 5%;
  align-items: center;
}

.title_container h1 {
  color: white;
}

.loc_headers_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  position: relative;
  color: white;
  margin: 0;
  height: 40px;
}

.ad_headers_container {
  width: 90%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  position: relative;
  color: white;
  margin: 0;
  position: relative;
  left: 5%;
  text-align: center;
}
.show_ad {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  overflow: auto;
}

.add_input_margin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 500px;
  background-color: white;
  box-shadow: 24px;
  overflow: auto;
}
.smaller_frame {
  width: 700px;
}

.larger_frame {
  width: 1200px;
}

.add_input_margin input {
  margin-bottom: 15px;
}

.add_input_margin select {
  margin-bottom: 15px;
}

.location_content {
  position: relative;
  width: 90%;
  height: 50%;
  left: 5%;
}

.list_container {
  position: relative;
  width: 90%;
  left: 5%;
}

.list_container h1 {
  color: white;
  font-size: 14px;
}

.headers_container {
  width: 90%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  position: relative;
  color: white;
  margin: 0;
  position: relative;
  left: 5%;
  text-align: center;
}

.qr_headers_container {
  width: 90%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  position: relative;
  color: white;
  margin: 0;
  position: relative;
  left: 5%;
  text-align: center;
}

.geo_headers_container {
  width: 90%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  position: relative;
  color: white;
  margin: 0;
  position: relative;
  left: 5%;
  text-align: center;
}

.add_button {
  background-color: #429321;
  border: none;
  border-radius: 2px;
  color: white;
  width: 140px;
  height: 42px;
  font-size: var(--btn-font-size);
}

#main_menu {
  height: 400px;
  width: 400px;
  position: absolute;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  top: 45%;
  left: 47.5%;
}

#beacon_img {
  position: absolute;
  left: 40%;
  top: 40%;
}

.buttons_main {
  width: 100px;
  height: 100px;
}

.card_btn_wrapper {
  display: flex;
  justify-content: center;
}

.beacon_details_holder {
  display: flex;
  flex-direction: column;
  justify-self: flex-start;
  align-items: flex-start;
}

.grid_layout_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  left: 5%;
  width: 70%;
  grid-gap: 5px;
  overflow: auto;
}

.grid_layout_container h3 {
  font-size: 12px;
  text-align: center;
}

.top_left_analytics {
  background-color: #374148;
  display: grid;
  grid-template-columns: auto auto;
  color: white;
  overflow: auto;
  grid-column-start: 1;
  grid-column-end: 3;
}

.item1 {
  grid-column-start: 1;
  grid-column-end: 3;
}

.item2 {
  grid-column-start: 1;
  grid-column-end: 8;
}

.top_right_analytics {
  background-color: #374148;
  color: white;
  grid-column-start: 3;
  grid-column-end: 7;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 25px;
  overflow: hidden;
}

.recharts-responsive-container {
  align-self: center;
}

line {
  stroke: rgba(240, 240, 240, 0.1);
}

.top_left_analytics p {
  font-size: 8px;
  font-family: MontserratThin;
}

.center_left_analytics {
  background-color: #374148;
  color: white;
  grid-column-start: 1;
  grid-column-end: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.labelCell {
  font-size: 8px;
  align-self: center;
  justify-content: center;
}

.heatmap_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  height: 20px;
}

.heatRangeStyle {
  width: 80%;
  height: 15px;
  display: flex;
  flex-direction: row;
  position: relative;
}

.heatRangeCell {
  width: 90%;
  height: 10px;
  text-align: center;
  font-size: 12px;
}

.center_right_analytics {
  background-color: #374148;
  color: white;
  grid-column-start: 5;
  grid-column-end: 7;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 300px;
}

.bottom_container {
  background-color: #374148;
  color: white;
  grid-column-start: 1;
  grid-column-end: 7;
}

.right_analytics {
  background-color: #374148;
  color: white;
  width: 22.5%;
  overflow-y: auto;
  padding: 10px;
  height: 100vh;
}

.analytics_header_container {
  display: flex;
  flex-direction: row;
  left: 2.5%;
  width: 95%;
  justify-content: space-between;
  position: relative;
}

.recharts-legend-item {
  font-size: 8px;
}

.amplify-card {
  padding-inline: 0;
}

.date_picker_style {
  padding: 0.5rem;
  border: 1px solid hsl(210deg 6% 70%);
  border-radius: 0.25rem;
}

.right_analytics h4 {
  font-size: 10px;
  margin: 0;
}

.right_analytics h3 {
  margin: 0;
  text-align: center;
}

::placeholder {
  color: white;
}

.add_input_margin ::placeholder {
  color: rgb(117, 117, 117);
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.MuiDialogActions-root button {
  background-color: #429321;
  border: none;
  color: white;
  font-size: small;
}
@media only screen and (max-width: 1000px) {
  .business_info_w_users {
    flex-direction: column;
  }
}
